import React from "react";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import LanguageProvider from "./src/utils/LanguageProvider";

import userReducer from "state/user";
import cityScreenReducer from "state/cityScreen";

// i18n Translations
import enTranslation from "./lang/en";
import frTranslation from "./lang/fr";

const withProvider = ({ element }) => {
  const store = configureStore({
    reducer: {
      user: userReducer,
      cityScreen: cityScreenReducer,
    },
  });

  const messages = {
    en: enTranslation,
    fr: frTranslation,
  };

  return (
    <Provider store={store}>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin="true"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Roboto&family=Lato&family=Raleway&display=swap"
        rel="stylesheet"
      />
      <LanguageProvider messages={messages}>{element}</LanguageProvider>
    </Provider>
  );
};

export default withProvider;
