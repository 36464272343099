import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IntlProvider } from 'react-intl';

const LanguageProvider = ({ messages, children }) => {
  // const { locale } = useSelector((state) => state.languageReducer);
  const locale = 'fr';
  const dispatch = useDispatch();
  const setLocale = useCallback((newLocale) => dispatch({ type: 'change_locale', payload: {locale: newLocale} }),[dispatch])

  useEffect(()=>{
    const newLocale = navigator.language.substring(0,2) || 'en';
    setLocale(newLocale);
  }, [setLocale])

  return (
    <IntlProvider
      locale={locale}
      messages={messages[locale]}
    >
      {React.Children.only(children)}
    </IntlProvider>
  )
}

export default LanguageProvider;