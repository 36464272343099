import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userFront: {},
  fetchingLogin: false,
  failedLogin: false,
  failedCheckLogin: false,
  resetPwdIsSend: false,
  fetchingUpdateUser: false,
  failedUpdateUser: false,
  twoAuth: false,
  fetchingTwoAuth: false,
  closePage: false,
  list: []
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginStart(state) {
      state.fetchingLogin = true;
      state.failedLogin = false;
      state.failedCheckLogin = false;
    },
    loginEnd(state, action) {
      state.fetchingLogin = false;
      state.failedLogin = false;
      if(action.payload) {
        state.userFront = action.payload;
      }
    },
    updateList(state, action) {
      state.list = action.payload;
    }
  },
});

export const { loginStart, loginEnd, updateList } = userSlice.actions;
export default userSlice.reducer;