import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fetching: false,
  cityScreens: [],
  selectedCityScreen: {}
};

const cityScreenSlice = createSlice({
  name: "cityScreen",
  initialState,
  reducers: {
    startGetCS(state) {
      state.fetching = true;
    },
    endGetCS(state, action) {
      state.fetching = false;
      if (action.payload) {
        state.cityScreens = action.payload;
      }
    },
    selectCS(state, action) {
      state.selectedCityScreen = action?.payload || initialState.selectedCityScreen;
    }
  },
});

export const { startGetCS, endGetCS, selectCS } = cityScreenSlice.actions;
export default cityScreenSlice.reducer;
